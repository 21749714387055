export const platformList = [
  {
    platform: "windows",
    img: `${process.env.REACT_APP_PUBLIC_URL}/windows.svg`,
    subject: "Creative Cast for Windows",
    description: "Windows 8/8.1/10/11",
    download: `${process.env.REACT_APP_PUBLIC_URL}/download/Creative Cast.zip`,
  },
  {
    platform: "mac",
    img: `${process.env.REACT_APP_PUBLIC_URL}/apple.svg`,
    subject: "Creative Cast for Mac",
    description: "MacOS 10.12 and above",
    download: `${process.env.REACT_APP_PUBLIC_URL}/download/Creative Cast.dmg`,
  },
  {
    platform: "iphone",
    img: `${process.env.REACT_APP_PUBLIC_URL}/apple.svg`,
    subject: "Creative Cast for iPhone",
    description: "iOS 14.0 and above",
    download: "https://apps.apple.com/us/app/creative-cast/id1566812880",
  },
  {
    platform: "android",
    img: `${process.env.REACT_APP_PUBLIC_URL}/android.svg`,
    subject: "Creative Cast for Android",
    description: "Android 9.0 and above",
    download: "https://play.google.com/store/apps/details?id=com.optoma.sender",
  },
  {
    platform: "chromeOS",
    img: `${process.env.REACT_APP_PUBLIC_URL}/chrome.svg`,
    subject: "Creative Cast for Chromebook",
    description: "Android 9.0 and above",
    download:
      "https://play.google.com/store/apps/details?id=com.optoma.chromesender",
  },
];
