import React from "react";
import "./ListItem.css";

interface ListItemProps {
  icon: string;
  subject: string;
  description: string;
  url: string;
}

const ListItem: React.FC<ListItemProps> = ({
  icon,
  subject,
  description,
  url,
}) => {
  return (
    <a
      className="list-item"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img className="list-icon" src={icon} alt={subject} />
      <div className="list-content">
        <p className="list-subject">{subject}</p>
        <p className="list-description">{description}</p>
      </div>
    </a>
  );
};

export default ListItem;
