import React from "react";
import { Button } from "@mantine/core";
import { useImage } from "../utils";
import "./Binding.css";

interface BindingProps {
  canUnbind: boolean;
  type?: string;
  icon: React.ReactNode;
  children: React.ReactNode;
  onClick?: (e: any) => Promise<void> | void;
}

const Binding: React.FC<BindingProps> = ({
  canUnbind,
  type,
  icon,
  children,
  onClick = () => {},
}) => {
  const bindingIcon = useImage(
    `${process.env.REACT_APP_PUBLIC_URL}/unbind.svg`
  );

  return (
    <div className="binding">
      <div id="binding-signin">
        <div>
          {icon}
          {children}
        </div>
        {canUnbind && (
          <Button
            color="red"
            variant="subtle"
            leftIcon={
              <object
                type="image/svg+xml"
                data={bindingIcon}
                aria-label={`${type} binding`}
              />
            }
            onClick={onClick}
          >
            Unbind
          </Button>
        )}
      </div>
    </div>
  );
};

export default Binding;
