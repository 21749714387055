import React, { createContext, ReactNode } from "react";
import { useLocalStorage } from "@mantine/hooks";

export const AuthContext = createContext<any>("Unauthorized");
type Props = { children: ReactNode };

const AuthProvider = ({ children }: Props) => {
  const [isAuth, setAuth, removeAuth] = useLocalStorage<string>({
    key: "isAuth",
    defaultValue: "false", //testing needed
    // "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoidXNlciIsInVzZXJOYW1lIjoiYnJ1Y2UubGlhbyIsImRpc3BsYXlOYW1lIjoiIiwic3ViIjoiMGE0NjFhYjgtY2MwNy00OWJlLWJhNjQtMTRjN2U3ODQ0ZjU2IiwiZW1haWwiOiJicnVjZS5saWFvQG9wdG9tYS5jb20iLCJqdGkiOiI0NDYxOTY3Ni1iZTdiLTRjOWItYjQwYy0yZGIwMjUzMjI0YTYiLCJpYXQiOjE2ODEzMjQ3ODI5NjcsImV4cCI6MTY4MTMyNzM3NDk2N30.tGo4HeUK7nXrumhbZp1G7ELqer1dCxjWO1r-z6Lms-W-APmHrJ4DxAP9nv-xyjkztV7OeTWZU9l5W0fN7Mm8eA",
    getInitialValueInEffect: true,
    serialize: (value) => {
      /* return value serialized to string */
      console.debug("serialize", value);
      return JSON.stringify(value);
    },
    deserialize: (sessionStorageValue) => {
      /* parse sessionStorage string value and return value */
      console.debug("deserialize", sessionStorageValue);
      return JSON.parse(sessionStorageValue);
    },
  });

  return (
    <AuthContext.Provider value={[isAuth, setAuth, removeAuth]}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
