import React from "react";
import "./Footer.css";

const Footer: React.FC = () => {
  return (
    <div className="footer">
      <footer>
        <p className="footer-copyright">
          Copyright © 2022 Optoma Corporation. All rights reserved.{" "}
          <a
            className="footer-aaa"
            href="https://www.optoma.com/terms-conditions/"
          >
            Terms and Conditions of Use{" "}
          </a>{" "}
        </p>
      </footer>
    </div>
  );
};

export default Footer;
