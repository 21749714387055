import React, { ReactNode } from "react";
import "./Header.css";

interface HeaderProps {
  children: ReactNode;
}

const Header: React.FC<HeaderProps> = ({ children }) => {
  return (
    <div className="header">
      <a href="https://www.optoma.com/" rel="noopener noreferrer">
        <img
          className="header-logo"
          src="https://www.optoma.com/wp-content/themes/optoma-corporate-v2/assets/images/logo.svg?9271a37"
          alt="Optoma Logo"
        />
      </a>
      {children}
    </div>
  );
};

export default Header;
